@import "../../styles/shared/_variables.scss";

.expertCoaching {

    .insetPage {
        padding: 40px 32px;
        margin-top: 50px;
    }

    .onDemandSessionsContainer {
        padding-bottom: 72px;

        .maskedBg {
            position: absolute;
            z-index: 0;
            width: 100%;
            // height: 100%;
            min-height: 560px;
            max-width: 2064px;
            // bottom: -1px;
            left: 50%;
            transform: translateX(-50%);
            background-image: linear-gradient(0deg, #EAF5FE, transparent);
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
        
            @media (min-width: 2064px) {
                max-width: none;
            }
        
            .mask {
                width: 100%;
                height: 100%;
                background-image: url(../../assets/images/da-swoosh.svg);
                background-position: bottom center;
                background-repeat: no-repeat;
                z-index: 6;
                position: absolute;
                bottom: 0;
                left: 0;
        
                background-size: 522px auto;
        
                @media (min-width: 522px) {
                background-size: 800px auto;
                }
        
                @include media-breakpoint-up(md) {
                background-size: auto;
                }
        
                @media (min-width: 2064px) {
                background-size: cover;
                }
            }
            img {
                position: relative;
                z-index: 1;
        
                &.image {
                opacity: 0;
                transition: opacity 0.85s cubic-bezier(0.72, 0, 0.28, 1);
                }
                &.image-loaded {
                opacity: 1;
                }
        
                @include media-breakpoint-down(md) {
                position: absolute;
                left: 50vw;
                }
        
                @include media-breakpoint-down(sm) {
                left: 40vw;
                }
            }
        
            &.onDemandSessions {
                min-height: 580px;
            }
        }
    }

    .onDemandSessionsRow {
        margin-left: 0px;
        margin-right: 0px;
    }

    .coveo-events-list {
        .groupHeader {
            font-size: 38px;
            text-align: center;
        }

        .eccDescription {
            text-align: center;
            color: #181818;
            font-size: 22px;
            line-height: 24px;
            margin-bottom: 3rem;
        }
    }
}

.keyBenefits {
    padding: 0px 75px;
    .groupHeader {
      text-align: center;
      font-size: 32px;
    }

    ul {
        padding: 15px 25px;
        list-style-type: disc;
        margin-bottom: 0;
    }

    b {
        @include sansbold();
    }
}

.comePrepared {
    padding-bottom: 40px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 40px;
  
    @include media-breakpoint-down(sm){
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .threeCol-col {
      max-width: 360px;
      margin-bottom: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    }
  
    .threeCol-col:nth-child(2) {
      margin-left: 40px;
      margin-right: 40px;
  
      @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    .threeCol-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
      padding: 20px;
  
      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
      }
    }
  
    .description {
        text-align: center;
        margin-bottom: 10px;
        flex: 1;
    }
  
    h2.groupHeader {
        text-align: center;
        font-size: 32px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    h3.groupHeader {
        margin-bottom: 0;
        text-align: center;
        font-size: 20px;
    }

    h3.groupHeader {
        @include media-breakpoint-down(sm) {
            padding: 0 0 20px 0;
            font-size: 20px;
        }
    }

    h2.groupHeader {
        @include media-breakpoint-down(sm) {
            font-size: 32px;
            margin-bottom: 0;
        }
    }
}

.requestSuccessReview {
    text-align: center;
    .groupHeader {
      font-size: 32px;
    }

    .btn {
        @include media-breakpoint-down(md) {
          margin-left: 0;
          margin-bottom: 20px;
          display: block;
          width: 100%;
        }
      }
}